import Image from 'next/image';
import Link from 'next/link';

import { Heading, Pg } from './Text.js';

export default function Error404({ title, copy }: { title?: string; copy?: string }) {
  return (
    <div className="relative isolate min-h-full">
      <div className="brightness-[0.2] absolute inset-0 -z-10 w-full object-cover object-top h-[calc(100vh-64px)]">
        <video
          autoPlay
          loop
          muted
          className="hue-rotate-continuous w-full h-full object-cover object-top"
          poster="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDABQODxIPDRQSEBIXFRQYHjIhHhwcHj0sLiQySUBMS0dARkVQWnNiUFVtVkVGZIhlbXd7gYKBTmCNl4x9lnN+gXz/2wBDARUXFx4aHjshITt8U0ZTfHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHz/wAARCABDAIUDASIAAhEBAxEB/8QAGQAAAwEBAQAAAAAAAAAAAAAAAAIDAQQG/8QAGxABAQEBAQEBAQAAAAAAAAAAAAECEhETAyH/xAAZAQEBAQEBAQAAAAAAAAAAAAAAAQIDBAX/xAAYEQEBAQEBAAAAAAAAAAAAAAAAERIBAv/aAAwDAQACEQMRAD8A8pMuj8ssz+f9Xxh9X16XEV/OOjCWMr4jz+kimVckzFMxx7whoZkhmIRjKZlhCJ1PSthNRrnCIbQ3HTqI6jrwjk3HN+mXbvKO8O/nq88uPkL38w6aXDpmFM5bMnkcO9dstzFswmYrlnrOT5ikJk8ZiQ8ayNTKQMrWUyQtT1FKSrCJaiWotomljWXPrKdy6LCWNca5xDgK+BasMaF9bKR0ikPKlKeaIzFpTSozRppIkWlb0j03tYkV9Zan0zohFLSWlui3RCNtJaLot0RqCkrbS+kagDPQQheh0j63qu2XXK802ac/Vb3UymXTNt7cvY+iZTLr7Hbl+g+hlMursduX6M+hky6rst25/pWd1cmXRdlu0Oqz2mVytds7S9YZaivYSBCNADbQAAAAIgAAAAAwAAAAAAAAAA//2Q=="
        >
          <source src="/assets/graphics/404bg.mp4" type="video/mp4" />
          <Image
            src="/assets/graphics/404bg.png"
            alt="404 background"
            width="1920"
            height="968"
            placeholder="blur"
            blurDataURL="L8Em;~sp00WW4AW-05ay0]oNswjb"
          />
        </video>
      </div>
      <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
        <Pg size={3} className="leading-8 text-white">
          404
        </Pg>
        <Heading size={1} tagName="h1" className="mt-4 text-white">
          {title ?? 'Page not found'}
        </Heading>
        <Pg size={3} className="mt-4 text-white/70 sm:mt-6">
          {copy ?? "Sorry, we couldn't find the page you're looking for."}
        </Pg>
        <div className="mt-10 flex justify-center">
          <Link href="/home" className="text-sm font-semibold leading-7 text-white">
            <span aria-hidden="true">&larr;</span> Back to home
          </Link>
        </div>
      </div>
    </div>
  );
}
