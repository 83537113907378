import Error404 from '@/components/Error404.js';
import { TemplateLanding } from '@/components/TemplateLanding.js';

export default function Default404Page() {
  return (
    <TemplateLanding pageName="404" fullHeight>
      <Error404 />
    </TemplateLanding>
  );
}
