import clsx from 'clsx';

export const Heading = (
  props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> & {
    tagName: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'span';
    size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
    color?: string;
  },
) => {
  const { size, tagName, children, className, ...mainProps } = props;
  const Component = tagName;
  return (
    <Component
      className={clsx(
        {
          // todo, make all variants good
          'text-4xl font-bold leading-9 tracking-tight': size === 1,
          'text-3xl font-bold leading-9 tracking-tight': size === 2,
          'text-2xl font-bold leading-9 tracking-tight': size === 3,
          'text-xl font-semibold': size === 4,
          'text-lg font-semibold': size === 5,
          'text-md font-semibold': size === 6,
          'text-sm font-semibold': size === 7,
          'text-xs font-semibold': size === 8,
          'text-2xs font-semibold': size === 9,
          'text-3xs font-semibold': size === 10,
          'text-gray-900 dark:text-white': !props.color,
        },
        className,
      )}
      style={{ color: props.color }}
      {...mainProps}
    >
      {children}
    </Component>
  );
};

export const Pg = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLParagraphElement>,
    HTMLParagraphElement
  > & {
    size: 1 | 2 | 3 | 4;
  },
) => {
  const { size, children, className, ...pgProps } = props;
  return (
    <p
      className={clsx(
        {
          // todo, make all the variants
          'mt-1 text-lg text-gray-500 dark:text-white': size === 1,
          'mt-1 text-md text-gray-500 dark:text-white': size === 2,
          'mt-1 text-sm text-gray-500 dark:text-white': size === 3,
          'mt-1 text-xs text-gray-500 dark:text-white': size === 4,
        },
        className,
      )}
      {...pgProps}
    >
      {children}
    </p>
  );
};
